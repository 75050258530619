import { Container, TableContainer } from "./styles";

const SalesTable = ({ salesData }) => {
  return (
    <Container>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th>Data</th>
              <th>Qtde.</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(salesData.dailyEntries).map((key) => {
              const data = salesData.dailyEntries[key];
              return (
                <tr key={key}>
                  <td>{data.date}</td>
                  <td>{data.salesTotal}</td>
                  <td>{data.commissionTotal}</td>
                </tr>
              );
            })}
            <tr>
              <td>Total de vendas</td>
              <td>{salesData.allSalesTotal}</td>
              <td>{salesData.allCommissionTotal}</td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
    </Container>
  );
};
export default SalesTable;
