import GlobalContext from "context";
import {
  StyledContent,
  StyledHeader,
  StyledLayout,
  StyledTitle,
} from "libs/styled-components/styles";
import AffiliateLinkSection from "modules/home/components/AffiliateLinkSection";
import { useContext, useMemo } from "react";
import SupportContent from "modules/home/components/SupportContent";
import Footer from "components/Footer";
import SalesTable from "modules/home/components/SalesTable";
import WithdrawlButton from "modules/home/components/WithdrawlButton";

const Home = () => {
  const { user } = useContext(GlobalContext);
  const salesData = useMemo(() => {
    return user.transactions.reduce(
      (acc, curr) => {
        const date = curr.createdAt.split("T")[0];
        acc["allCommissionTotal"] += curr.commissionAmount;
        acc["allSalesTotal"] += 1;

        if (acc.dailyEntries[date]) {
          acc.dailyEntries[date].commissionTotal += curr.commissionAmount;
          acc.dailyEntries[date].salesTotal += 1;
        } else {
          acc.dailyEntries[date] = {
            date: date,
            commissionTotal: curr.commissionAmount,
            salesTotal: 1,
          };
        }
        return acc;
      },
      { allCommissionTotal: 0, allSalesTotal: 0, dailyEntries: {} }
    );
  }, [user.transactions]);
  return (
    <StyledLayout>
      <StyledHeader>
        <h2>Olá, {user.name}</h2>
      </StyledHeader>
      <StyledContent>
        <SupportContent />
        <AffiliateLinkSection />
        <StyledTitle>Comissões</StyledTitle>
        <SalesTable salesData={salesData} />
        <WithdrawlButton salesData={salesData} />
      </StyledContent>
      <Footer />
    </StyledLayout>
  );
};
export default Home;
