import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 1rem 2rem;
  width: 100%;
  & > h2 {
    padding: 0.5rem 2rem;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Merriweather Regular";
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primary[100]};
    border: 1px solid ${({ theme }) => theme.colors.primary[300]};
    border-radius: 1rem;
  }
  & > h3 {
    padding: 0.5rem 2rem;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Merriweather Regular";
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.primary[300]};
    border-radius: 1rem;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.dark[200]};
  overflow: hidden;
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 1rem;
    text-align: center;
  }
  th,
  td {
    font-size: 1.4rem;
    border-right: 1px solid ${({ theme }) => theme.colors.dark[200]};
    &:last-child {
      border-right: none;
    }
  }
  th {
    padding: 1rem;
  }
  td {
    padding: 0.5rem 1rem;
  }
  tbody > tr:last-child {
    background-color: ${({ theme }) => theme.colors.primary[100]};
  }
`;
