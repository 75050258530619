import { Container, ListBox, TargetContainer, TextContainer } from "./styles";
import { Check } from "assets/svg";

const PageContent = () => {
  return (
    <Container>
      <TextContainer>
        <h1>
          Programa de Afiliados da <span>Cápsula do Tempo</span>
        </h1>

        <p>
          Na <span>Cápsula do Tempo</span> acreditamos no potencial de
          crescimento junto de nossos parceiros para levar aos noivos e ao
          mercado de casamentos inovações que transformem a experiência de
          registrar e viver o dia mais importante de suas vidas.
        </p>
      </TextContainer>
      <TargetContainer>
        <h2>O Afiliado Ideal</h2>
        <p>
          Buscamos parceiros que gostem de atuar neste segmento e estejam
          alinhados com nossa missão e valores.
        </p>
        <h3>Missão</h3>
        <p>
          "Entregar lembranças que façam o coração dos nossos usuários sorrir."
        </p>
        <h3>Visão</h3>
        <p>"Lembrar as pessoas dos seus momentos especiais."</p>
        <h3>Valores</h3>
        <ul>
          <li>Crescimento e construção coletiva;</li>
          <li>Inovação e evolução contínua;</li>
          <li>Foco em produzir emoções positivas.</li>
        </ul>
      </TargetContainer>

      <ListBox>
        <h2>Vantagens de ser nosso afiliado:</h2>
        <ul>
          <li>
            <Check height="30" width="30" outline="white" color="transparent" />
            <p>Afiliação imediata e sem burocracia</p>
          </li>
          <li>
            <Check height="30" width="30" outline="white" color="transparent" />
            <p>
              Materiais e links para divulgação já disponíveis na plataforma
            </p>
          </li>
          <li>
            <Check height="30" width="30" outline="white" color="transparent" />
            <p>
              Desconto para os usuários por meio do seu link exclusivo de
              afiliado
            </p>
          </li>
        </ul>
      </ListBox>
    </Container>
  );
};

export default PageContent;
