import { StyledTitle } from "libs/styled-components/styles";
import { Container } from "./styles";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Container>
      <StyledTitle>Saiba mais</StyledTitle>
      <ul>
        <li>
          Sobre a
          <Link to={"./about"} target="_blank">
            Cápsula do Tempo
          </Link>
        </li>
        <li>
          Programa de
          <Link to={"./program"} target="_blank">
            Afiliados
          </Link>
        </li>
      </ul>
    </Container>
  );
};
export default Footer;
