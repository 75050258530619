import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 2rem;
  gap: 1rem;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  & > h3 {
    font-size: 1.2rem;
  }
  & > p {
    font-size: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  background-color: ${({ theme }) => theme.colors.primary[300]};
  color: ${({ theme }) => theme.colors.light[50]};
  border: 1px solid ${({ theme }) => theme.colors.primary[300]};
  border-radius: 2rem;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.colors.dark[300]};
      border: 1px solid ${({ theme }) => theme.colors.dark[300]};
      cursor: none;
    `}
`;

export const InstructionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem 2rem;
  & > ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 0 2rem;
    & > li {
      font-size: 1.4rem;
    }
  }
`;
