import { useCallback, useState } from "react";
import {
  Container,
  InfoContainer,
  ButtonContainer,
  InstructionContainer,
} from "./styles";
import SimpleModal from "components/SimpleModal";

const WithdrawlButton = ({ salesData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const onOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <>
      <Container>
        <InfoContainer>
          <h3>Valor mínimo para resgate: R$ 2.000</h3>
          <p>
            Quando seu saldo atingir o valor mínimo, você poderá realizar um
            Resgate.
          </p>
        </InfoContainer>
        <ButtonContainer
          disabled={salesData.allCommissionTotal < 2000}
          onClick={onOpenModal}
        >
          Resgatar
        </ButtonContainer>
      </Container>
      <SimpleModal
        isOpen={isModalOpen}
        handleClose={onCloseModal}
        title={`Resgatar`}
      >
        <InstructionContainer>
          <h3>Instruções para resgate</h3>
          <p>Envie um email para contato@capsuladotempoeventos.com.br com:</p>
          <ul>
            <li>Assunto: Resgate</li>
            <li>Seu nome completo</li>
            <li>CPF</li>
            <li>Valor a ser resgatado</li>
            <li>Conta bancária para depósito</li>
            <li>Nota fiscal em anexo</li>
          </ul>
        </InstructionContainer>
      </SimpleModal>
    </>
  );
};

export default WithdrawlButton;
