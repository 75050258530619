import {
  BrowserRouter,
  Routes as BrowserRoutes,
  Route,
} from "react-router-dom";
import PublicRouter from "./PublicRouter";
import Login from "modules/login/screens/Main";
import Register from "modules/register/screens/Main";
import PrivateRouter from "./PrivateRouter";
import ForgotPassword from "modules/forgotPassword/screens/Main";
import Home from "modules/home/screens/Main";
import About from "modules/about/screens/Main";
import Program from "modules/program/screens/Main";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route
          path="/"
          element={
            <PrivateRouter>
              <Home />
            </PrivateRouter>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRouter>
              <Login />
            </PublicRouter>
          }
        />

        <Route
          path="/register"
          element={
            <PublicRouter>
              <Register />
            </PublicRouter>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRouter>
              <ForgotPassword />
            </PublicRouter>
          }
        />
        <Route
          path="/about"
          element={
            <PrivateRouter>
              <About />
            </PrivateRouter>
          }
        />
        <Route
          path="/program"
          element={
            <PrivateRouter>
              <Program />
            </PrivateRouter>
          }
        />
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default AppRouter;
